import axios from "axios";
import Config from "./Config";

const PROD_SERVER = "https://next-v2-be.servicehuset.dk"
const DEV_SERVER = "http://localhost:8090"

export default class BeConnector {

    static REMOTE_SERVER = PROD_SERVER;
    axiosInstance;

    constructor() {
        BeConnector.REMOTE_SERVER = PROD_SERVER;
        if (!Config.IS_PRODUCTION)
            BeConnector.REMOTE_SERVER = DEV_SERVER;

        console.log("Server set to:", BeConnector.REMOTE_SERVER);
    }

    async init(force = false) {
        if (this.axiosInstance && !force) return;


        console.log("Initializing connectionMaster - using server: ", BeConnector.REMOTE_SERVER);

        this.axiosConfig = {
            baseURL: BeConnector.REMOTE_SERVER,
            timeout: 30000,
            /*            auth: {
                            username: 'janedoe',
                            password: 's00pers3cret'
                        }*/
        }

        this.axiosInstance = axios.create(this.axiosConfig);

        //await this.testConnection();

    }

    async offerSentMessage(uid, data) {
        const result = await this.post("/nnxt/offerSentMessage", { uid: uid, data });
        console.log("offerSentMessage", result);
        return result;
    }


    async loadCustomerdata(id) {
        const result = await this.post("/nnxt/getCustomerData", { id: id });
        return result;
    }


    async saveNextUserSettings(name, settings) {
        const result = await this.post("/nnxt/saveNextUserSettings", { userName: name, settings: settings });
        return result;
    }

    async loadTeamStates() {
        const result = await this.post("/nnxt/loadTeamStates", {});
        return result;
    }

    async getNextUserSettings(name) {
        const result = await this.post("/nnxt/getNextUserSettings", { userName: name });
        return result;
    }

    async getCustomerIssues(areas = undefined, noCache = false) {
        const result = await this.post("/nnxt/getCustomerIssues", { areas, noCache });
        return result;
    }
    async getTeamsCalendarData(startDate = new Date(), endDate = undefined) {
        const result = await this.post("/nnxt/getTeamsCalendarData", { startDate, endDate });
        return result;
    }

    async saveTeamsCalendarData(list, userName) {
        const result = await this.post("/nnxt/saveTeamsCalendarData", { list: list, userName: userName });
        return result;
    }


    async post(url, data) {

        let result = {};

        try {
            result = await this.axiosInstance.post(url, data);
        } catch (error) {
            console.log(`Failure calling: ${url}`, error);
            return { status: "FAIL" };
        }
        return result.data;
    }


    async submitNewCustomer(reqObj) {

        let result = {};
        const url = '/nnxt/addNewCustomer';

        try {
            result = await this.axiosInstance.post(url, reqObj);
        } catch (error) {
            console.log(`Failure calling: ${url}`, error);
            return { status: "FAIL" };
        }
        return result.data;
    }

    async getRatingList(reqObj) {

        let result = {};
        const url = '/nnxt/getULRatings';

        try {
            result = await this.axiosInstance.get(url, reqObj);
        } catch (error) {
            console.log(`Failure calling: ${url}`, error);
            return { status: "FAIL" };
        }
        return result.data;
    }

    async getOfferServerInfo(newInfo, retryCount = 5) {

        let result = {};
        const url = '/nnxt/getOfferCustomerInfo';

        try {
            result = await this.axiosInstance.post(url, { newInfo: newInfo });
        } catch (error) {
            console.log(`Failure calling: ${url}`, error);
            if (retryCount > 0) {
                return await this.getOfferServerInfo(newInfo, retryCount - 1);
            }

            return { status: "FAIL" };
        }
        return result.data;
    }

}

const beConnectorInstance = new BeConnector();
export { beConnectorInstance };

