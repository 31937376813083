
import React, { useEffect, useState } from 'react';

import {
    App, Block, BlockFooter, Button, f7,
    f7ready, Link, List,
    ListInput, LoginScreen, Navbar,
    NavRight, Page, Panel, Popup, View
} from 'framework7-react';


import Connector from '../Connector';
import { sharedData } from '../data/SharedData';
import { beConnectorInstance } from '../js/BeConnector';
import Config from '../js/Config';
import routes from '../js/routes';
import OfferHandler from '../OfferHandler';
import { contextMenuController, TinyContextMenu } from '../utils/ContextMenu/TinyContextMenu';

import Darkmode from 'darkmode-js';
const darmModeOptions = {
    bottom: '10px', // default: '32px'
    right: '10px', // default: '32px'
    //left: '32px', // default: 'unset'
    //time: '0.5s', // default: '0.3s'00
    mixColor: '#fff', // default: '#fff'
    backgroundColor: '#fff',  // default: '#fff'
    buttonColorDark: '#100f2c',  // default: '#100f2c'
    buttonColorLight: '#fff', // default: '#fff'
    saveInCookies: true, // default: true,
    label: '🌓', // default: ''
    autoMatchOsTheme: true // default: true
}
const darkmode = new Darkmode(darmModeOptions);
setTimeout(() => {
    darkmode.showWidget();
    //darkmode.toggle();
}, 1000);
const emailLookup = {
    alice: { email: "alice@servicehuset.dk", area: [1, 5, 3, 4] },
    //    rune: { email: "rune@servicehuset.dk", area: [99, 1, 3, 4, 5], startPage: "/rating/" },
    rune: { email: "rune@servicehuset.dk", area: [99, 1, 3, 4, 5] },
    steen: { email: "steen@servicehuset.dk", area: [5, 1, 3, 4] },
    camilla: { email: "camilla@servicehuset.dk", area: [4, 3, 5, 1] },
    yvonne: { email: "yvonne@servicehuset.dk", area: [3, 4, 5, 1] },
    sophia: { email: "sophia@servicehuset.dk", area: [5, 1, 3, 4] },
    tina: { email: "tina@servicehuset.dk" },
    tonny: { email: "tonny@servicehuset.dk", area: [5, 1] },
    niklas_DISABLED: { email: "niklas@servicehuset.dk", area: [1, 5] },
    pia: { email: "pia@servicehuset.dk", area: [99, 5, 1, 3, 4, 5] },
    daniel: { email: "daniel@servicehuset.dk", area: [99, 1, 3, 4, 5] },
    annette: { email: "annette@servicehuset.dk", area: [5, 1, 3, 4] },
}

const pwords = ["service123", "service123.", "servo123", "singASong.1", "Yuv55536"];

let loginData = {};
let initialBaseUrl = undefined;
Connector.initialBaseUrl = undefined;


beConnectorInstance.init();
handleBrowserUrl();


window.addEventListener('hashchange', (_e) => {
    //console.log("LOCATION CHANGE HASH", e);
    //handleBrowserUrl();
    window.location.reload();

})

function handleBrowserUrl() {
    var hash = window.location.hash;
    if (hash) {
        initialBaseUrl = hash.split("#!")[1];

        Connector.initialBaseUrl = initialBaseUrl;
    }

}




/*window.addEventListener('beforeunload', function (e) {
  // Cancel the event
  e.preventDefault(); // If you prevent default behavior in Mozilla Firefox prompt will always be shown
  // Chrome requires returnValue to be set
  e.returnValue = 'Er du sikker på du ønsker at navigere væk fra NEXT?';
  return "Er du sikker på du ønsker at navigere væk fra NEXT?";
});*/

const findUserData = (name) => {
    if (!name) return {};
    return emailLookup[name] || {};

}

const MyApp = () => {
    // Login screen demo data
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [darkMode, setDarkMode] = useState(true);
    //const [baseUrl, setBaseUrl] = useState("/offers-page/");
    const [baseUrl, _setBaseUrl] = useState("/start-screen/");
    //const [baseUrl, _setBaseUrl] = useState("/newTask/22269520");
    //const [baseUrl, _setBaseUrl] = useState("/teamView/");
    //const [baseUrl, _setBaseUrl] = useState("/customers/");
    //const [baseUrl, _setBaseUrl] = useState("/customer-page/22269520");
    //const [baseUrl, _setBaseUrl] = useState("/teams/");
    //const [baseUrl, _setBaseUrl] = useState("/settings/");
    //const [baseUrl, setBaseUrl] = useState("/rating/");
    if (!Config.IS_PRODUCTION) {
        // Connector.initialBaseUrl = initialBaseUrl = "/teams/";

    }


    Connector.registerStateDispatch("DARK_MODE", setDarkMode);

    // Framework7 Parameters
    const f7params = {
        name: 'SHv2', // App name
        theme: 'auto', // Automatic theme detection
        // App routes
        routes: routes,
        // Register service worker
        serviceWorker: Config.IS_PRODUCTION_REAL ? {
            path: '/service-worker.js',
        } : {},
    };

    useEffect(() => {


        document.addEventListener("keyup", (event) => {
            if (event.key === 'Enter') {

                if (event.target.form?.id === "loginIdForm" && event.target.name === "password") {
                    //console.log("keyup triggered");

                    doLogin();
                    event.preventDefault();

                }

            }
        });
    }, []);


    const doLogin = () => {
        //Connector.setUser(username, password);

        if (!emailLookup[Connector.username] || !pwords.includes(Connector.password)) {
            f7.dialog.alert('Forkert brugernavn eller password :(', () => {
                //  setUsername("");
                setPassword("");
            });
            return;
        }

        localStorage.setItem("SH-NEXT-LOGIN", JSON.stringify({ name: Connector.username, pw: "???" }));
        let data = findUserData(Connector.username);
        sharedData.setCurrentUserBaseInfo(data);
        //storageMaster.setStoreKey("NEXT_LOCAL_STORE_AREA_" + sharedData.getArea());
        /*    if (Connector.password !== 'service123' || !data.email) {
              return;
            } */

        setUsername("");
        setPassword("");
        console.log("username", Connector.username);

        Connector.userArea = Connector.getInstance().area = data.area || [1];
        Connector.currentArea = Connector.userArea[0];
        console.log("app.jsx Connector.currentArea", Connector.currentArea);

        Connector.setUsername(Connector.username);

        Connector.dispatchState("SET_AREA", "" + Connector.currentArea);
        Connector.ready = true;
        f7.loginScreen.close();

        //Connector.dispatchState("changeArea", Connector.getInstance().area);

        /*f7.dialog.alert('Username: ' + username + '<br>Password: ' + password, () => {
          f7.loginScreen.close();
        });*/
    }
    f7ready(() => {
        // Call F7 APIs here

        /*    if (!username || username.length) {
              f7.loginScreen.open('#my-login-screen', true);
              //setTimeout(f7.loginScreen.open, 550, 'my-login-screen');
            } */


        let logJson = localStorage.getItem('SH-NEXT-LOGIN');// my-login-screen
        loginData = JSON.parse(logJson || "{}");

        if (!logJson || !loginData.name) {
            f7.loginScreen.open('#my-login-screen', true);

        }
        else {
            Connector.username = loginData.name;
            Connector.password = 'service123';
            Connector.setUsername(Connector.username);

            let udata = findUserData(Connector.username)
            sharedData.setCurrentUserBaseInfo(udata);
            sharedData.setCurrentUserName(Connector.username);
            //storageMaster.setStoreKey("NEXT_LOCAL_STORE_AREA_" + sharedData.getArea()); // TODO: make this work better 
            udata.startPage && (Config.startPage = udata.startPage);

            Connector.userArea = Connector.getInstance().area = udata.area;
            Connector.currentArea = Connector.userArea[0];
            OfferHandler.hasChanged = true;
            Connector.ready = true;
        }
        //f7.loginScreen.open();

        if (initialBaseUrl) {
            let m7 = f7;
            setTimeout(() => {
                m7.views.main.router.navigate(initialBaseUrl);
            }, 500);
        }

        //console.log("myCount: " + mycount);


    });
    /*
      setTimeout(() => {
        console.log("Let there be light---------------");
        setDarkMode(false);
      }, 10000);
    */

    return (
        <App {...f7params} dark={darkMode}>
            <TinyContextMenu controller={contextMenuController} />
            {/* <Panel left reveal themeDark resizable opened cover={false}> */}
            <Panel left
                cover
                dark
            >
                <View url="/panel-menu/" linksView=".view-main" />
            </Panel>



            {/* Left panel with cover effect*/}
            {/* <Panel left cover themeDark>
                <View>
                    <Page>
                        <Navbar title="Left Panel" />
                        <Block>Left panel content goes here</Block>
                    </Page>
                </View>
            </Panel> */}


            {/* Right panel with reveal effect*/}
            {/* <Panel right reveal themeDark>
                <View>
                    <Page>
                        <Navbar title="Right Panel" />
                        <Block>Right panel content goes here</Block>
                    </Page>
                </View>
            </Panel> */}


            {/* Your main view, should have "view-main" class */}
            <View
                main
                className="safe-areas"
                url={baseUrl}
                browserHistory={false}

            />

            {/* Popup INFO*/}
            <Popup id="popupInfo">
                <View>
                    <Page>
                        <Navbar title="Info">
                            <NavRight>
                                <Link popupClose>Luk</Link>
                            </NavRight>
                        </Navbar>
                        <Block>
                            INSERT INFO HERE!
                        </Block>
                    </Page>
                </View>
            </Popup>


            {/* Popup */}
            <Popup id="my-popup">
                <View>
                    <Page>
                        <Navbar title="Bruger info">
                            <NavRight>
                                <Link popupClose>Luk</Link>
                            </NavRight>
                        </Navbar>
                        <Block>
                            <p>Navn: {Connector.username}</p>
                            <p>Email: {findUserData(Connector.username).email}</p>
                        </Block>
                    </Page>
                </View>
            </Popup>

            <LoginScreen id="my-login-screen">
                <View>
                    <Page loginScreen>
                        <p className="text-align-center"><img src="/icons/192x192.png" /></p>
                        <p className="text-align-center"><img src="/logo/NEXT.png" width="300px" /></p>
                        <List form action="#" id="loginIdForm">
                            <ListInput
                                type="text"
                                name="username"
                                placeholder="navn"
                                value={username}
                                onInput={(e) => { setUsername(e.target.value.toLowerCase()); Connector.username = e.target.value.toLowerCase(); }}
                            ></ListInput>
                            <ListInput
                                type="password"
                                name="password"
                                placeholder="Password"
                                value={password}
                                onInput={(e) => { setPassword(e.target.value); Connector.password = e.target.value }}
                                data=""
                            ></ListInput>
                        </List>
                        <div className="text-align-center" style={{ width: "200px", margin: "auto" }}>
                            <Button fill raised small onClick={(_e) => doLogin()}>Login</Button>
                        </div>

                        <BlockFooter>
                            Dette login er kun til personale<br />
                        </BlockFooter>
                    </Page>
                </View>
            </LoginScreen>
        </App >
    )
}




export default MyApp;